import React from 'react';

import Layout from '../components/Layout';
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

export const PressPageTemplate = ({press, title, image }) => {
  const getDate = (dd) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dd);
    const y = date.getFullYear();
    const d = date.getDate();
    return `${monthNames[date.getMonth()].toUpperCase()} ${d < 10 ? '0'+d : d}, ${y}`;
  };
  return (
  <main role="main" className="press-page">
    <Helmet>
      <title>Press</title>
    </Helmet>
    <div>
      {/* Begin Banner */}
      <div className="legal faq mb-0"style={{color: 'white', textAlign: 'center', "backgroundImage": `linear-gradient(90deg, rgba(146, 43, 184, 0.69), rgba(246, 31, 128, 0.69) , rgba(254, 164, 120, 0.69)), url(${image && image.childImageSharp ? image.childImageSharp.fluid.src : image})`, "backgroundSize": "cover", "backgroundPosition": "center"}}>
        {/*<h2 style="color:white;">
      PRESS
      </h2>*/}
        <h1 className="display-5 text-white text-center" style={{fontSize: '7rem'}}>{title}</h1>
      </div>
      {/* End Banner */}
      {/* Begin Second section */}

        {
          press.map((item, i) => {
            if (new Date(item.date).getTime() > new Date().getTime()) return null;
            return (
              <div className="container features wd-75 mt-5 card ca-shadow-sm rounded-2" key={i}>
              <div className="row">
                <div className="col">
                  <div className="row points-text " style={{paddingTop: '15px', paddingBottom: '15px'}}>
                    <div className="col-md-5 mobile-px-d5">
                      <div className="grad-left" style={{"height": "240px", "backgroundImage": `linear-gradient(90deg, rgba(146, 43, 184, 0.69), rgba(246, 31, 128, 0.69) , rgba(254, 164, 120, 0.69)), url(${item.image && item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image})`, "backgroundSize": "cover", "backgroundPosition": "center"}} />
                      {/* <img class="rounded-2 "  src={`${item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image}`}  alt="" />*/}
                    </div>
                    <div className="col-md-7 py-3 pl-5 pr-4  vertical-middle">
                      <h3> {item.title}</h3>
                      <h5>
                        {getDate(item.date)}
                      </h5>
                      <p> {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            );
          })
        }

      {/* End Second section */}
      <div className="jumbotron text-center py-5 pt-4" style={{background: 'none'}}>
        <a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">Schedule visit</a>
        <span className="call" style={{fontSize: '17px', fontWeight: 'bold'}}>
      <p style={{color: 'black'}}>
        or call <a className="btn btn-link f-7" style={{color: 'black'}} href="tel:+18888590145" role="button">888-859-0145</a>
      </p></span>
      </div>
    </div>

  </main>
  )
};


const PressPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <PressPageTemplate
        press={frontmatter.press}
        title={frontmatter.title}
        image={frontmatter.image}
      />
    </Layout>
  )
};

export default PressPage;

export const pageQuery = graphql`
  query PressPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "press-page" } }) {
      frontmatter {
        title
        image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        press {
          title
          description
          date
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
